import {Link} from 'react-router-dom';

export function BlogCard({Title , Desc , Tags , Name, id }){
    return(
        <>
        
            <div class="overflow-hidden shadow-lg rounded-lg h-90 w-full lg:w-full m-auto">
                <div className="px-10 py-20 border rounded lg:px-5 lg:py-10 xl:py-20">
                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase transition-colors duration-200 hover:text-blue-400 cursor-pointer">
                <Link to="/portfolio" state={{ id : id }}>
                    {Name}
                    </Link>
                </p>
                <p
                    className="inline-block max-w-xs mx-auto mb-3 text-2xl font-extrabold leading-7"
                    aria-label="Read article"
                    title="Nori grape silver beet broccoli kombu beet"
                    >
                    {Title}
                </p>
                <p className="max-w-s mb-2 text-gray-700 text-justify" dangerouslySetInnerHTML={{__html: Desc}}>
                </p>
                <div class="flex flex-wrap justify-starts items-center mt-4">
                            {
                                Tags.map((tag)=>  <div class="text-xs mr-2 py-1.5 px-4 text-gray-700 bg-blue-100 rounded-2xl">
                                #{tag}

                            </div>)
                            }
                          
                        </div>
                </div>
            </div>

        </>
    );
}