import { useState , useEffect , Fragment , useContext} from "react";
import {db , storage} from "../Firebase";
import {collection ,query, getDocs,where, updateDoc, doc} from "firebase/firestore";
import { AuthContext } from "../Context/AuthContext";
import { Listbox , Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import {getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject} from "firebase/storage";
import { useNavigate } from 'react-router-dom';

const department_options = [
	{ name: 'Practicing Physician' },
	{ name: 'Practicing Surgeon' },
	{ name: 'Industry' },
	{ name: 'Retired' },
	{ name: 'Other' },
  ]

export const Update = () => {
    const {currentUser} = useContext(AuthContext);
    const [users,setUsers]=useState([]);
    const navigate = useNavigate();
    
    useEffect(()=>{
        const getUsers=async()=>{
          const data=await getDocs(query(collection(db,"Alumni"), where("uid","==",currentUser.uid)));
          updateSets(data.docs.map((doc)=>({...doc.data(),id:doc.id})));
          setUsers(data.docs.map((doc)=>({...doc.data(),id:doc.id})));
        }
        getUsers();
    },[]);
    
    const [Name,setName]=useState("");
    const [Batch,setBatch]=useState("");
    const [Department,setDepartment]=useState("");
    const [LastName,setLastName]=useState("");
    const [Location,setLocation]=useState("");
    const [MiddleName,setMiddleName]=useState("");
    const [Phone,setPhone]=useState("");
    const [Special,setSpecial]=useState("");
    const [Web,setWeb]=useState("");
    const [About,setAbout]=useState("");
    const [imageURL,setImageURL]=useState("");
    const [oimagePath,setoImagePath]=useState("");
    const [Display,setDisplay]=useState("");

    const [progress, setProgress] = useState("hidden");
	const [done, setDone] = useState("hidden");
    const [image, setimage] = useState([]);
    const [imagePath,setImagePath]=useState("");
    const [mainprogress, setMainprogress] = useState("hidden");
    

    const [error, setError] = useState({
        image: '',
		gen: '',
	  });

    const updateSets = (usersM) => {
        usersM.map((user)=>{
            setName(user.Name);
            setBatch(user.Batch);
            setDepartment(department_options[department_options.findIndex(x => x.name === user.Department)]);
            setLastName(user.LastName);
            setLocation(user.Location);
            setMiddleName(user.MiddleName);
            setPhone(user.Phone);
            setSpecial(user.Special);
            setWeb(user.Web);
            setAbout(user.About ? user.About : "");
            setDisplay(user.Display);
            setImageURL(!user.imageURL ? "https://firebasestorage.googleapis.com/v0/b/alumni-portal-bc1dc.appspot.com/o/image%2Ficon-user.png?alt=media&token=227c1769-ed75-4865-b0c8-7e43a235a810" : user.imageURL);
            setoImagePath(user.imagePath);
            setImagePath(user.imagePath);
        });
    }

    const deleteImage = (path, npath) => {
        const storage = getStorage();
        const desertRef = ref(storage, path);
        deleteObject(desertRef).then(() => {
            setoImagePath(npath);
            console.log("old deleted")
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        const uploadFile =()=>{
            setError(prev => {
                return { ...prev, image: '' };
                });
            
          if (image.length !== 0){
              if (image.size/1000000 < 1.2){
                  const name = new Date().getTime() + image.name
                  const storageRef = ref(storage, `image/${name}`);
                  const uploadTask = uploadBytesResumable(storageRef, image);
                  uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                      const progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      console.log("Upload is " + progress + "% done");
                      if (progress < 100){
                          setProgress("visible");
                          setDone("hidden");
                      } else if (progress === 100) {
                          setDone("visible");
                          setProgress("hidden");
                          setImagePath(`image/${name}`);
                          deleteImage(oimagePath, `image/${name}`);
                      }
                  },
                  (error) => {
                      console.log(error);
                  },
                  () => {
                      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                      setImageURL(downloadURL);
					  localStorage.setItem("profileImage", "");
					  localStorage.setItem("profileImage", downloadURL);
                      });
                  }
                  );
                  console.log(name);
              } else {
                  setProgress("hidden");
                  setDone("hidden");
                  setError(prev => {
                      return { ...prev, image: "Maximum image size allowed: 1 MB." };
                      });
              }
          }
        }
        image && uploadFile();
      }, [image])

    const updateUser = async (id,Name,Batch,Department,LastName,Location,MiddleName,Phone,Special,Web,About,Display,e) => {
        setMainprogress("visible");
        e.preventDefault();
        const userDoc = doc(db, "Alumni", id);
        const newDat={Name:Name, Batch:Batch, Department:Department['name'] , LastName:LastName, Location:Location, MiddleName:MiddleName, Phone:Phone, Special:Special, Web:Web, About:About, Display:Display, imageURL:imageURL, imagePath:imagePath};
        console.log(users);
        console.log(newDat);        
          try{
          await updateDoc(userDoc,newDat);
          console.log("Updated");
          setMainprogress("hidden");
          navigate("/portfolio",{state:{id: id}});
        }
          catch(error){
            console.log(error);
          }
        };
        return(<>
        
        {

            <>
                    {
                        users.map((user)=>{
                            return(
                                <div className="flex justify-center items-center  pt-16 ">
                <div className="w-full flex justify-center ">
                                <div className="w-auto lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
						<div className="flex justify-center items-center"><img className="object-cover w-60 h-60 rounded-full group-hover:ring-white" src={imageURL} alt="" /></div>
						<form className="px-8 pt-12 pb-8 mb-4 bg-white rounded" >
                                
						<div className="mb-4 mt-4 lg:flex lg:justify-between">
						<div className="w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="firstName" className="block mb-2 text-sm font-bold text-gray-700">First Name</label>
						<input
							className="w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-900 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
							id="firstName"
							type="text"
							placeholder="First Name"
                            defaultValue={Name}
							onChange={(event)=>{setName(event.target.value)}}
							required/>

						</div>

						<div className="lg:w-1/2 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="middletName" className="block mb-2 text-sm font-bold text-gray-700">Middle Name</label>
						<input
							className="w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-900 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
							id="middleName"
							type="text"
							placeholder="Middle Name"
                            defaultValue={MiddleName}
							onChange={(event)=>{setMiddleName(event.target.value)}}/>

						</div>

						<div className="w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="lastName" className="block mb-2 text-sm font-bold text-gray-700">Last Name</label>
									<input
										className="w-full px-3 py-2 text-sm leading-tight text-gray-900 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
										id="lastName"
										type="text"
										placeholder="Last Name"
                                        defaultValue={LastName}
										onChange={(event)=>{setLastName(event.target.value)}}
										required/>

						</div>
						</div>

                        <div className="mb-4 mt-4 lg:flex lg:justify-between">
							<div className="w-full mb-4 lg:mr-2 lg:mb-0">
								<label for="formFile" className="block mb-2 text-sm font-bold text-gray-700">Upload new photo (Max 1 MB)</label>
								<input className="form-control
								block
								w-full
								px-3
								py-1.5
								text-base
								font-normal
								text-gray-700
								bg-white bg-clip-padding
								border border-solid border-gray-300
								rounded
								transition
								ease-in-out
								m-0
								focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="formFile" onChange={(e)=>setimage(e.target.files[0])}/>
								{error.image && <span className='text-sm font-bold text-red-700'>{error.image}</span>}
							</div>
							<div  className={"lg:pt-8 lg:mr-2 lg:mb-0 "+ progress} role="status">
								<svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
									<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
								</svg>
							</div>
							<div className={"lg:pt-8 lg:mr-2 lg:mb-0 "+ done} role="status">
								<svg class="w-8 h-8 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
								</svg>
								<span class="sr-only">Loading...</span>
							</div>
							
							</div>
							
                        <div className='w-full lg:w-full'>
                                <label className='block mb-2 text-sm font-bold text-gray-700' >About</label>
                                <textarea className='bg-gray-100 rounded-md border leading-normal resize w-full h-20 py-2 px-3 shadow-inner border border-gray-400 text-sm placeholder-gray-700 focus:outline-none focus:bg-white'
                                defaultValue={About}
                                onChange={(event)=>{setAbout(event.target.value)}}></textarea>
                            </div>   


					<div className="mb-4 mt-4 lg:flex lg:justify-between">
						<div className="w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="location" className="block mb-2 text-sm font-bold text-gray-700">Current Location</label>
						<input
							className="w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-900 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
							id="location"
							type="text"
							placeholder="City, State, Country"
                            defaultValue={Location}
							onChange={(event)=>{setLocation(event.target.value)}}
							required/>

						</div>

						<div className="lg:w-1/2 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="batch" className="block mb-2 text-sm font-bold text-gray-700">Batch (admission year)</label>
						<input
							className="w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-900 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
							id="batch"
							type="text"
							placeholder="eg: 1973"
                            defaultValue={Batch}
							onChange={(event)=>{setBatch(event.target.value)}}
							required/>

						</div>

						<div className="lg:w-1/2 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="phone" className="block mb-2 text-sm font-bold text-gray-700">Phone Number</label>
									<input
										className="w-full px-3 py-2 text-sm leading-tight text-gray-900 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
										id="phone"
										type="text"
                                        defaultValue={Phone}
										placeholder="eg: +1 (111)-111-1111"
										onChange={(event)=>{setPhone(event.target.value)}}
										/>

						</div>
						</div>

					<div className="mb-4 mt-4 lg:flex lg:justify-between">
						<div className="lg:w-1/3 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="location" className="block mb-2 text-sm font-bold text-gray-700">Job Profile</label>
						<div>
							<Listbox value={Department} onChange={setDepartment}>
								<div className="relative mt-1">
									<Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
										<span className="block truncate">{Department['name']}</span>
											<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
												<SelectorIcon
													className="h-5 w-5 text-gray-400"
													aria-hidden="true"
												/>
												</span>
											</Listbox.Button>
											<Transition
												as={Fragment}
												leave="transition ease-in duration-100"
												leaveFrom="opacity-100"
												leaveTo="opacity-0"
											>
												<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
												{department_options.map((deptmnt, deptmntIdx) => (
													<Listbox.Option
													key={deptmntIdx}
													className={({ active }) =>
														`relative cursor-default select-none py-2 pl-5  ${
														active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
														}`
													}
													value={deptmnt}	
													>
													{({ Department }) => (
														<>
														<span
															className={`block truncate ${
															Department ? 'font-medium' : 'font-normal'
															}`}
														>
															{deptmnt.name}
														</span>
														{Department ? (
															<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
															<CheckIcon className="h-5 w-5" aria-hidden="true" />
															</span>
														) : null}
														</>
													)}
											</Listbox.Option>
											))}
										</Listbox.Options>
										</Transition>
										</div>
									</Listbox>
								</div>

						</div>

						<div className="lg:w-1/3 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="special" className="block mb-2 text-sm font-bold text-gray-700">Specialty</label>
						<input
							className="w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-900 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
							id="special"
							type="text"
							placeholder="eg: Internal Medicine"
                            defaultValue={Special}
							onChange={(event)=>{setSpecial(event.target.value)}}
							required/>

						</div>

						<div className="lg:w-1/2 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="phone" className="block mb-2 text-sm font-bold text-gray-700">Web address (Hospital, Practice / Linkedin)</label>
									<input
										className="w-full px-3 py-2 text-sm leading-tight text-gray-900 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
										id="phone"
										type="text"
										placeholder="eg: my.clevelandclinic.org/staff/vineet-punia"
                                        defaultValue={Web}
										onChange={(event)=>{setWeb(event.target.value)}}
										/>

						</div>
						</div>
						
						
							<div className="mb-12 lg:flex lg:justify-between">
								<div className="w-full mb-4 lg:mr-2 lg:mb-0">
								<label for="display" className="mb-2 text-sm font-bold text-gray-700 mr-4">Are you willing to be contacted by other alumni? (Display Contact Info)</label>
								<input 
									id="display"
									type="checkbox" 
									value=""
                                    checked={Display}
									onChange={(event)=>{setDisplay(event.target.checked)}} 
									class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
								</div>
							</div>
                            <div className="mb-6 flex justify-end">
                                <div  className={"pt-4 mb-4 mr-4 "+ mainprogress} role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                </div>
								<button
									className="w-auto px-4 py-2 font-bold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-blue-700 focus:shadow-outline"
									type="submit"
                                    onClick={(e)=>{updateUser(user.id,Name,Batch,Department,LastName,Location,MiddleName,Phone,Special,Web,About,Display,e)}}
								>
									Update
								</button>
								{error.gen && <span className='block mt-2 text-sm font-bold text-red-700'>{error.gen}</span>}
							</div>

						</form>
					</div>
                    </div>
                    </div>
                            )
                        })
                    }
                
            </>
        }
     
        </>
        );
}