import { useState, useContext, Fragment} from "react";
import { Menu, Transition } from '@headlessui/react'
import { AuthContext } from "../Context/AuthContext";
import { DotsVerticalIcon } from '@heroicons/react/outline'
import { View } from 'react-native';
import logo from './../Assets/logo.png';

export const Nav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {dispatch} = useContext(AuthContext);
    const {currentUser} = useContext(AuthContext);
    const approval = localStorage.getItem("approval");
    const admin = localStorage.getItem("admin");
    const handleLogout = (e) => {
      dispatch({type:"LOGOUT"});
      window.location.reload();
    }

    const imageUrl = !localStorage.getItem("profileImage") || localStorage.getItem("profileImage") === "" ? "https://firebasestorage.googleapis.com/v0/b/alumni-portal-bc1dc.appspot.com/o/image%2Ficon-user.png?alt=media&token=227c1769-ed75-4865-b0c8-7e43a235a810" : localStorage.getItem("profileImage");
    

    // To handle Tailwind  className.
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

    return (
      <div className="bg-white-100">
        <div className="px-4 py-6 mx-auto lg:py-8 sm:max-w-xl lg:max-w-full lg:max-w-screen-xl lg:px-24 lg:px-8">
          <div className="relative flex justify-between lg:space-x-16">
            <a
              href="/dashboard"
              aria-label="HomePage"
              title="HomePage"
              className="inline-flex items-center"
            >
              <span className="text-xl font-bold tracking-wide text-black-100 uppercase inline-flex items-center">
              <img src={logo} alt="Logo" className="items-left ml-3.5" style={{width: 100, height: 65}}/>
              GGSMC Alumni
              </span>
            </a>
              

            <ul className="flex items-center hidden space-x-8 lg:flex">
            {currentUser !== null && approval === "true" && admin === "true" ? (
                <li>
                  <a
                    href="/admin"
                    aria-label="Admin"
                    title="Admin"
                    className="font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                  >
                    Admin
                  </a>
                </li>
                ) : (
                  ""
                )}
                
              {currentUser !== null && approval === "true" ? (
                <>
                  <li>
                  <a
                    href="/alumni"
                    aria-label="Alumni"
                    title="Alumni"
                    className="font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                  >
                  Alumni
                  </a>
                </li>
                <li>
                  <a
                    href="/blogs"
                    aria-label="Blogs"
                    title="Blogs"
                    className="font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                  >
                    Blogs
                  </a>
                </li>
                <li>
                  <a
                    href="/calendar"
                    aria-label="Calendar"
                    title="About us"
                    className="font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                  >
                    Calendar
                  </a>
                </li>
                <Menu
                      as="div"
                      className="relative"
                    >
                      <div>
                        <Menu.Button className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:opacity-80">
                          <span className="sr-only">Open options</span>
                          <img
                            src={imageUrl}
                            alt=""
                            className="object-cover w-10 h-10 rounded-full"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                            <a
                              href="/update"
                              aria-label="Update"
                              title="Update"
                              className="block px-4 py-2 font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                            >
                            Update Profile
                            </a>
                            </Menu.Item>
                            <Menu.Item>
                            <button
                              className="block px-4 py-2 font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                              onClick={handleLogout}
                            >
                              Sign out
                            </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </>
                ) : (
                  <li>
                  <a
                    href="/login"
                    aria-label="Login"
                    title="Login"
                    className="font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                  >
                   Login
                  </a>
                </li>
                )}

              </ul>
              


            <div className="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <img
                            src={imageUrl}
                            alt=""
                            className="object-cover w-10 h-10 rounded-full"
                          />
              </button>
              {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full z-20">
                  <div className="p-5 bg-white border rounded shadow-sm">
                    <div className="flex items-center justify-between mb-4">
                      <div>
                      <a
                        href="/dashboard"
                        aria-label="HomePage"
                        title="HomePage"
                        className="inline-flex items-left"
                      >
                        <span className="text-xl font-bold tracking-wide text-black-100 uppercase inline-flex items-center">
                        GGSMC Alumni
                        </span>
                      </a>
                                </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg className="w-5 text-black-600" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                    <ul className="space-y-4">
                        {currentUser !== null && approval === "true" ? (
                          <>
                          <li>
                            <a
                              href="/alumni"
                              aria-label="Alumni"
                              title="Alumni"
                              className="font-medium tracking-wide text-black-700 transition-colors duration-200 hover:text-blue-900"
                            >
                              Alumni
                            </a>
                          </li>
                          <li>
                            <a
                              href="/blogs"
                              aria-label="Blogs"
                              title="Blogs"
                              className="font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                            >
                              Blogs
                            </a>
                          </li>
                          <li>
                            <a
                              href="/calendar"
                              aria-label="Calendar"
                              title="Calendar"
                              className="font-medium tracking-wide text-black-700 transition-colors duration-200 hover:text-blue-900"
                            >
                              Calendar
                            </a>
                          </li>
                          <li>
                            <a
                              href="/update"
                              aria-label="Update"
                              title="Update"
                              className="font-medium tracking-wide text-black-700 transition-colors duration-200 hover:text-blue-900"
                            >
                              Update Profile
                            </a>
                          </li>
                            <li>
                            <button
                              className="font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                              onClick={handleLogout}
                            >
                              Sign out
                            </button>
                          </li>
                          </>
                        ) : (
                          <li>
                          <a
                            href="/login"
                            aria-label="Login"
                            title="Login"
                            className="font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                          >
                          Login
                          </a>
                        </li>
                        
                        )}

                        {currentUser !== null && approval === "true" && admin === "true" ? (
                        <li>
                          <a
                            href="/admin"
                            aria-label="Admin"
                            title="Admin"
                            className="font-medium tracking-wide text-black-100 transition-colors duration-200 hover:text-blue-900"
                          >
                            Admin
                          </a>
                        </li>
                        ) : (
                          ""
                        )}
                        </ul>
                       
                      
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <View style={{height: 2, width: '100%', backgroundColor: '#909090',}}></View>
      </div>
    );
  };