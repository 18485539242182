import { useEffect , useState , useContext} from 'react';
import { BlogRow } from './BlogRow';
import { collection, getDocs , query , where  } from "firebase/firestore";
import { db } from "../../Firebase";
import { AuthContext } from '../../Context/AuthContext';
export  function ShowBlogs(){
    const [blogs, setBlogs] = useState([])
    const {currentUser} = useContext(AuthContext);
    const getBlogs = async() => {
      const list = await getDocs(query(collection(db , "Blog"),where("uid","==",currentUser.uid)));
      setBlogs(list.docs.map( (doc) => ({id:doc.id , ...doc.data()}) ));
    }
    useEffect(() => {
        getBlogs();
    },[])

    return(
        <>
        
        <div className="flex flex-wrap  mx-auto px-4 sm:px-8 max-w-3xl">
            <div className="py-8">
                <div className="-mx-4 sm:-mx-16 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Title
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Content
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Tags
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                        Status
                                    </th>
                                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {blogs.length > 0 ?
                                    blogs.map((blog)=><BlogRow Title = {blog.Title} Desc={blog.Desc} Tags={blog.Tags} id={blog.id} />)
                                    : 
                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        <div className="flex items-center">
                                        
                                                <div className="ml-3">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                    No blogs have been published yet!
                                                    </p>
                                                </div>
                                            </div>
                                                </td>
                                }
                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
        </div>

        </>
    )};