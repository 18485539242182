
import { db } from "../../Firebase";
import { doc , deleteDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

export function BlogRow ({Title , Desc , Tags , id})  {
   
    const ref=doc(db,"Blog",id);
    const handleClick =async()=>{
    
    console.log(id)
    await deleteDoc(ref);
    window.location.reload();
    }
    
    return(
        <>
        <tr>
        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <div className="flex items-center">
        
                <div className="ml-3">
                    <p className="text-gray-900 whitespace-no-wrap">
                        {Title}
                    </p>
                </div>
            </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap" dangerouslySetInnerHTML={{__html: Desc}}>
                </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">
                    {Tags.join(" , ")}
                </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                <span aria-hidden="true" className="absolute inset-0 bg-green-200 opacity-50 rounded-full">
                </span>
                    active
                </span>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <button type="button" 
                    onClick={(e)=>handleClick(e)}
                    class="text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center">
                                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32">
                                        <path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path>
                                        </svg>
                                        <span class="sr-only">Delete</span>
                                        </button>
                </td>
                </tr>
                </>
                )
            };