import { Update } from "../Components/Update";
import { Nav } from "../Components/Nav";
import { Footer } from "../Components/Footer";
export default function UpdateP () {
    return(
      <>       
        <Nav/>
        <Update/>
        <Footer/>
      </>
    );
}