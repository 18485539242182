    export  function BlogHeader() {
        const goToTop = () => {
            window.scrollTo({
                top: 500,
                behavior: 'smooth',
            });
        };
        return (
            <div className="relative bg-blue-900 mb-10">
            <div className="absolute inset-x-0 bottom-0">
                <svg
                viewBox="0 0 224 12"
                fill="currentColor"
                className="w-full -mb-1 text-white"
                preserveAspectRatio="none"
                >
                <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
                </svg>
            </div>
            <div className="px-4 py-16 mx-auto sm:max-w-xl lg:max-w-full lg:max-w-screen-xl lg:px-24 lg:px-8 lg:py-20">
                <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl lg:max-w-2xl sm:text-center">
                <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                    Blogs on everything{' '}
                    <span className="relative inline-block">
                    GGSMC{' '}
                    <div className="w-full h-3 -mt-3 bg-blue-400" />
                    </span>
                </h2>
                <><button type="button" 
                    onClick={goToTop}
                    class="text-white duration-300 transform border border-gray-400 rounded-full hover:text-white-accent-400 hover:border-white-accent-400 hover:shadow hover:scale-110 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center">
                        
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                    >
                    <path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z" />
                    </svg>
                    <span class="sr-only">Blog</span>
                    </button>

                    <a type="button" 
                    href="/addblog" 
                    class="cursor-pointer ml-4 text-white duration-300 transform border border-gray-400 rounded-full hover:text-white-accent-400 hover:border-white-accent-400 hover:shadow hover:scale-110 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center">
                
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22Z" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3056 1.87868C17.1341 0.707107 15.2346 0.707107 14.063 1.87868L3.38904 12.5526C2.9856 12.9561 2.70557 13.4662 2.5818 14.0232L2.04903 16.4206C1.73147 17.8496 3.00627 19.1244 4.43526 18.8069L6.83272 18.2741C7.38969 18.1503 7.89981 17.8703 8.30325 17.4669L18.9772 6.79289C20.1488 5.62132 20.1488 3.72183 18.9772 2.55025L18.3056 1.87868ZM15.4772 3.29289C15.8677 2.90237 16.5009 2.90237 16.8914 3.29289L17.563 3.96447C17.9535 4.35499 17.9535 4.98816 17.563 5.37868L15.6414 7.30026L13.5556 5.21448L15.4772 3.29289ZM12.1414 6.62869L4.80325 13.9669C4.66877 14.1013 4.57543 14.2714 4.53417 14.457L4.0014 16.8545L6.39886 16.3217C6.58452 16.2805 6.75456 16.1871 6.88904 16.0526L14.2272 8.71448L12.1414 6.62869Z" />
                    </svg>
                    <span class="sr-only">Add Blog</span>
                    </a></>
                </div>
            </div>
            </div>
        );
    }