import React from 'react'

function Blogs({data}) {
    return (
        <>
    {
      data.length > 0 &&
      <div className="max-w-md px-4 mx-auto sm:px-7 lg:max-w-4xl lg:px-6">
        <div className='pb-8'>
          <p className='text-3xl font-bold inline border-b-4 text-gray-800 border-blue-700'>
            Blogs
          </p>
        </div>
      <div className="w-full">

        <div className="grid max-w-sm gap-5 mb-8 lg:grid-cols-1 sm:mx-auto lg:max-w-full overflow-y-auto">
        {
          data.map((blog)=>
          <div className="px-10 py-20 border rounded lg:px-5 lg:py-10 xl:py-20">
          <p
            className="inline-block max-w-xs mx-auto mb-3 text-2xl font-extrabold leading-7"
            aria-label="Read article"
            title="Title"
            >
            {blog.Title}
          </p>
          <p className="max-w-s mb-2 text-gray-700 text-justify" dangerouslySetInnerHTML={{__html: blog.Desc}}>
          </p>
          <div class="flex flex-wrap justify-starts items-center mt-4">
                            {
                                blog.Tags.map((tag)=>  <div class="text-xs mr-2 py-1.5 px-4 text-gray-700 bg-blue-100 rounded-2xl">
                                #{tag}

                            </div>)
                            }
                          
                        </div>
        </div>)
        }
      </div>
    </div>
  </div>
  }
    </>
      );
    };


export default Blogs