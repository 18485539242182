import narinder from './../../Assets/narinder.png';
import zora from './../../Assets/zora.png';
import vineet from './../../Assets/vineet.png';

export const Alumni = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mx-auto mb-10 lg:max-w-xl md:text-center">
          <p className="inline-block px-3 py-px mb-4 text-s font-semibold tracking-wider uppercase rounded-full bg-blue-900" style={{color: "white"}}>
            Know Our Alumni
          </p>
        </div>
        <div className="grid gap-10 mx-auto lg:grid-cols-1 lg:max-w-screen-lg">
          <div className="grid sm:grid-cols-3">
          <div className="relative rounded md:h-auto mt-5 w-full">
              <img
                className="absolute object-cover rounded w-full"
                src={narinder}
                alt="Narinder S Dhaliwal MD"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
              <p className="text-lg font-bold">Narinder S Dhaliwal MD</p>
              <p className="mb-4 text-xs text-gray-800">Internal Medicine</p>
              <p className="mb-4 text-sm tracking-wide text-gray-800">
              Welcome, all GGSMC alumni, faculty, and current students medical students/interns/House doctors,<br/><br/>
    
                Where GGSMC is celebrating completion of 50 years of success story in medical education, a common communication platform need has been identified for sometime to bring all of us closer to share information, stay connected  and exchange ideas and medical knowledge /expertise/experience as we all are spread all over the world. <br/><br/>
                
                Let us find a time to share our learned and achieve. <br/>
                Experience freely and bring closeness in our fraternity. <br/><br/>
                
                Special thanks to Dr Vineet Punia for his dedication to make the website a reality. <br/>
                
                Hopefully, all of those in specialities practice will bring topics and discussion to benefit a larger audience for the good of humanity. <br/><br/>
                
                With best regards <br/>
                God Bless GGSMC <br/><br/>
                
                Narinder S Dhaliwal MD <br/>
                Sacramento, CA , USA <br/>
              </p>
            </div>
          </div>
          <div className="grid sm:grid-cols-3">
          <div className="relative rounded md:h-auto mt-5 w-full">
              <img
                className="absolute object-cover rounded w-full"
                src={zora}
                alt="Zora Singh"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
              <p className="text-lg font-bold">Zora Singh</p>
              <p className="mb-4 text-xs text-gray-800">Professor of Anatomy (Retd.)</p>
              <p className="mb-4 text-sm tracking-wide text-gray-800">
              Human beings are considered social animals. Ethical communication has the power to heal us
              and restore harmony in the world.<br/><br/>

              <i><b>Jab lag duniya raheay nanak kichh suniye kichh kaheay (SGGS P 661)</b></i><br/>
              So long as we live in this world we must engage in listening and speaking.<br/><br/>

              Alumni associations have long been integral to educational institutions, offering a way for
              graduates to reconnect with their college experiences. These associations facilitate staying in
              touch post-graduation, providing a range of benefits including updates on college events, a
              broader professional network, exclusive job listings, career services, access to college resources,
              opportunities for professional development, and social events. Beyond financial contributions,
              alumni can also support their alma mater by mentoring current students, leveraging their success
              to enhance the institution&#39;s reputation and aid in recruitment. I urge both former and current
              faculty and students to contribute to the success of our institution by joining the Guru Gobind
              Singh Medical College Alumni website.<br/><br/>

              Zora Singh<br/>
              Professor of Anatomy (Retd.)<br/>
              GGSMCH, Faridkot<br/>
              </p>
            </div>
          </div>
          <div className="grid sm:grid-cols-3">
          <div className="relative rounded md:h-auto mt-5 w-full">
              <img
                className="absolute object-cover rounded w-full"
                src={vineet}
                alt="Vineet Punia"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
              <p className="text-lg font-bold">Vineet Punia, MD, MS</p>
              <p className="mb-4 text-xs text-gray-800">Epilepsy Specialist</p>
              <p className="mb-4 text-sm tracking-wide text-gray-800">
              My motivation to create this alumni website was sparked by Dr. Narinder Dhaliwal’s enthusiasm
              for our alma mater’s Golden Jubilee (50-years) celebrations. In the world of waxing-waning
              social media trends, I envision the website to be a constant resource to connect with friends, old
              and new. My greatest hope is that this website will allow us to tap into the rich tapestry of
              experiences and expertise our graduates offer. For students aspiring to further their career, this
              network should serve as an invaluable resource, offering guidance, support, and inspiration from
              those who have navigated similar paths. Through this initiative, we aim to cultivate a vibrant,
              supportive community that extends far beyond our campus boundaries, enriching both personal
              and professional lives.<br/><br/>
              Vineet Punia, MD, MS<br/>
              Cleveland Clinic,<br/>
              Cleveland, USA<br/>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };