import { useState , useEffect , Fragment, useContext } from "react";
import { db , auth , storage} from "../Firebase";
import { setDoc , doc } from "firebase/firestore";
import {ref, uploadBytesResumable , getDownloadURL, getStorage, deleteObject} from "firebase/storage";
import { createUserWithEmailAndPassword , updateProfile, onAuthStateChanged, getAuth, sendEmailVerification } from "firebase/auth";
import { Listbox , Transition } from '@headlessui/react'
import { Nav } from "../Components/Nav"
import { Footer } from "../Components/Footer"
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { AuthContext } from "../Context/AuthContext";
import { useNavigate } from 'react-router-dom';
import ball from './../Assets/basketball.png';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
  } from 'react-places-autocomplete';

const department_options = [
	{ name: 'Practicing Physician' },
	{ name: 'Practicing Surgeon' },
	{ name: 'Industry' },
	{ name: 'Retired' },
	{ name: 'Other' },
  ]

export default function SignUp(){

    const [Name,setName]=useState("");
	const [MiddleName,setMiddleName]=useState("");
	const [LastName,setLastName]=useState("");
	const [Location, setLocation]=useState("");
	const [Batch, setBatch]=useState("");
	const [Phone, setPhone]=useState("");
	const [Special, setSpecial]=useState("");
	const [Web, setWeb]=useState("");
	const [Display, setDisplay]=useState(false);
    const [Department,setDepartment]=useState(department_options[0]);
    const [regemail,setRegemail]=useState("");
    const [regpass,setRegpass]=useState("");
	const [cpass,setCpass]=useState("");
	const [image, setimage] = useState([]);
	const [imageURL, setImageURL] = useState("");
	const [imagePath, setImagePath] = useState("");
	const [progress, setProgress] = useState("hidden");
	const [done, setDone] = useState("hidden");
	const [mainprogress, setMainprogress] = useState("hidden");
 
	const handleAddressChange = address => {
		setLocation( address );
	};
   
	const handleAddressSelect = address => {
	  setLocation(address);
	};

	const [error, setError] = useState({
		cpass: '',
		gen: '',
		image: '',
	  });

	const navigate = useNavigate();

		const validateInput = e => {
			let { name, value } = e.target;
			setError(prev => {
				const stateObj = { ...prev, [name]: "" };
			
				switch (name) {
					case "cpass":
						if (value !== regpass) {
							stateObj[name] = "Password and Confirm Password does not match.";
						}
						break;
				
					default:
						break;
				}
				return stateObj;
			});
		}

	  const register = async () => {
		if (cpass !== regpass) {
			setError(prev => {
				return { ...prev, cpass: "Password and Confirm Password does not match." };
				});
		} else {
			setMainprogress("visible");
			try {
				await createUserWithEmailAndPassword(
				auth,
				regemail,
				regpass
			).then(credential=>{
				if(credential && credential.user)
				{
					const auth = getAuth();

					updateProfile(credential.user, {displayName : Name+" "+LastName, photoURL:imageURL}).then(data=>{
						sendEmailVerification(auth.currentUser)
						.then((r) => {
							console.log(r);
						}).catch(e => console.log(e.message));
					}).catch(e => console.log(e.message));

					const uid = credential.user.uid;
					setDoc(doc(db, "Alumni", credential.user.uid), {
						Name: Name ,Department: Department['name'] , email:regemail , uid:uid , imageURL:imageURL , imagePath: imagePath, MiddleName: MiddleName, LastName: LastName, Location: Location, Batch: Batch, Phone: Phone, Special: Special, Web: Web, Display: Display, Approved: false,
					}).then(()=>{
						localStorage.setItem("signup", true);
						setMainprogress("hidden");
						navigate("/login");
					});
				}
			}).catch(e => setError(prev => {
				setMainprogress("hidden");
				return { ...prev, gen: e.message };
				}));

			} catch (error) {
				setMainprogress("hidden");
				console.log(error.message);
			}
		}
	  };
	  
	useEffect(() => {
		
	onAuthStateChanged(auth, (currentUser) => {
		console.log(currentUser.user);
	});

	}, []);
	
	useEffect(() => {
	  const uploadFile =()=>{
		if (image.length !== 0){
			if (image.size/1000000 < 1.2){
				const name = new Date().getTime() + image.name
				const storageRef = ref(storage, `image/${name}`);
				const uploadTask = uploadBytesResumable(storageRef, image);
				uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log("Upload is " + progress + "% done");
					if (progress < 100){
						setProgress("visible");
						setDone("hidden");
					} else if (progress === 100) {
						setDone("visible");
						setProgress("hidden");
						setImagePath(`image/${name}`);
					}
				},
				(error) => {
					console.log(error);
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setImageURL(downloadURL);
					});
				}
				);
				console.log(name);
			} else {
				setProgress("hidden");
				setDone("hidden");
				setError(prev => {
					return { ...prev, image: "Maximum image size allowed: 1 MB." };
					});
			}
		}
	  }
	  image && uploadFile();
	}, [image])
	
	const handleSubmit = event => {
		event.preventDefault();
		 register();
	  };
    return (
        <>
		<Nav/>
         <div className="mx-auto">
			<div className="flex justify-center px-6 my-12">
				<div className="w-auto  lg:w-11/12 flex">
					<div style={{'--image-url': `url(${ball})`}}
						className="w-auto h-auto hidden lg:block lg:w-5/12  rounded-l-lg
						bg-[image:var(--image-url)] bg-contain bg-no-repeat bg-center "
					></div>
					<div className="w-auto lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
						<h3 className="text-4xl font-bold text-center text-gray-700 dark:text-white">Create an Account!</h3>
						<form className="px-8 pt-6 pb-8 mb-4 bg-white rounded" onSubmit={handleSubmit}>

						<div className="mb-4 mt-4 lg:flex lg:justify-between">
						<div className="w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="firstName" className="block mb-2 text-sm font-bold text-gray-700">First Name</label>
						<input
							className="w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
							id="firstName"
							type="text"
							placeholder="First Name"
							onChange={(event)=>{setName(event.target.value)}}
							required/>

						</div>

						<div className="lg:w-1/2 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="middletName" className="block mb-2 text-sm font-bold text-gray-700">Middle Name</label>
						<input
							className="w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
							id="middleName"
							type="text"
							placeholder="Middle Name"
							onChange={(event)=>{setMiddleName(event.target.value)}}/>

						</div>

						<div className="w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="lastName" className="block mb-2 text-sm font-bold text-gray-700">Last Name</label>
									<input
										className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
										id="lastName"
										type="text"
										placeholder="Last Name"
										onChange={(event)=>{setLastName(event.target.value)}}
										required/>

						</div>
						</div>
							<div className="mb-4 mt-4 lg:flex lg:justify-between">
							<div className="w-full mb-4 lg:mr-2 lg:mb-0">
								<label for="formFile" className="block mb-2 text-sm font-bold text-gray-700">Upload photo (Max 1 MB)</label>
								<input className="form-control
								block
								w-full
								px-3
								py-1.5
								text-base
								font-normal
								text-gray-700
								bg-white bg-clip-padding
								border border-solid border-gray-300
								rounded
								transition
								ease-in-out
								m-0
								focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="formFile" onChange={(e)=>setimage(e.target.files[0])}/>
								{error.image && <span className='text-sm font-bold text-red-700'>{error.image}</span>}
							</div>
							<div  className={"lg:pt-8 lg:mr-2 lg:mb-0 "+ progress} role="status">
								<svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
									<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
								</svg>
							</div>
							<div className={"lg:pt-8 lg:mr-2 lg:mb-0 "+ done} role="status">
								<svg class="w-8 h-8 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
									<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
								</svg>
								<span class="sr-only">Loading...</span>
							</div>
							
							</div>


					<div className="mb-4 mt-4 lg:flex lg:justify-between">
						<div className="w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="location" className="block mb-2 text-sm font-bold text-gray-700">Current Location</label>
						<PlacesAutocomplete
							value={Location}
							onChange={handleAddressChange}
							onSelect={handleAddressSelect}
							searchOptions={{ types: ['locality', 'country'] }}>
							{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<input
									{...getInputProps({
										placeholder: 'City, State, Country',
										className: 'location-search-input w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline',
									})}
									required />
									<div className="autocomplete-dropdown-container absolute z-50 cursor-default border rounded bg-white text-left text-sm text-gray-700">
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
										// inline style for demonstration purpose
										const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
										return (
										<div
											{...getSuggestionItemProps(suggestion, {
											className,
											style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
										);
									})}
									</div>
								</div>
								)}
						</PlacesAutocomplete>

						</div>
        
      

						<div className="lg:w-1/2 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="batch" className="block mb-2 text-sm font-bold text-gray-700">Batch (admission year)</label>
						<input
							className="w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
							id="batch"
							type="text"
							placeholder="eg: 1973"
							onChange={(event)=>{setBatch(event.target.value)}}
							required/>

						</div>

						<div className="lg:w-1/2 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="phone" className="block mb-2 text-sm font-bold text-gray-700">Phone Number</label>
									<PhoneInput
										defaultCountry="IN"
										className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
										placeholder="Phone Number"
										value={Phone}
										onChange={setPhone}
										/>

						</div>
						</div>

					<div className="mb-4 mt-4 lg:flex lg:justify-between">
						<div className="lg:w-1/3 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="location" className="block mb-2 text-sm font-bold text-gray-700">Job Profile</label>
						<div>
							<Listbox value={Department} onChange={setDepartment}>
								<div className="relative mt-1">
									<Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
										<span className="block truncate">{Department.name}</span>
											<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
												<SelectorIcon
													className="h-5 w-5 text-gray-700"
													aria-hidden="true"
												/>
												</span>
											</Listbox.Button>
											<Transition
												as={Fragment}
												leave="transition ease-in duration-100"
												leaveFrom="opacity-100"
												leaveTo="opacity-0"
											>
												<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
												{department_options.map((deptmnt, deptmntIdx) => (
													<Listbox.Option
													key={deptmntIdx}
													className={({ active }) =>
														`relative cursor-default select-none py-2 pl-5  ${
														active ? 'bg-blue-100 text-blue-900' : 'text-gray-700'
														}`
													}
													value={deptmnt}	
													>
													{({ Department }) => (
														<>
														<span
															className={`block truncate ${
															Department ? 'font-medium' : 'font-normal'
															}`}
														>
															{deptmnt.name}
														</span>
														{Department ? (
															<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
															<CheckIcon className="h-5 w-5" aria-hidden="true" />
															</span>
														) : null}
														</>
													)}
											</Listbox.Option>
											))}
										</Listbox.Options>
										</Transition>
										</div>
									</Listbox>
								</div>

						</div>

						<div className="lg:w-1/3 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="special" className="block mb-2 text-sm font-bold text-gray-700">Specialty</label>
						<input
							className="w-full lg:mr-8 px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
							id="special"
							type="text"
							placeholder="eg: Internal Medicine"
							onChange={(event)=>{setSpecial(event.target.value)}}
							required/>

						</div>

						<div className="lg:w-1/2 w-full mb-4 lg:mr-2 lg:mb-0">
						<label for="phone" className="block mb-2 text-sm font-bold text-gray-700">Web address (Hospital, Practice / Linkedin)</label>
									<input
										className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
										id="phone"
										type="text"
										placeholder="eg: my.clevelandclinic.org/staff/vineet-punia"
										onChange={(event)=>{setWeb(event.target.value)}}
										/>

						</div>
						</div>
						
							

							<div className="mb-4">
								<label className="block mb-2 text-sm font-bold text-gray-700" for="email">
									Email
								</label>
								<input
									className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
									id="email"
									type="email"
									placeholder="Email"
									onChange={(event)=>{setRegemail(event.target.value)}}
									required
								/>
							</div>
							<div className="mb-4 lg:flex lg:justify-between">
								<div className="w-full mb-4 lg:mr-2 lg:mb-0">
									<label className="block mb-2 text-sm font-bold text-gray-700" for="password">
										Password
									</label>
									<input
										className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
										id="password"
										type="password"
										placeholder="********"
										onChange={(event)=>{setRegpass(event.target.value)}}
										required
									/>
								</div>
								<div className="w-full lg:ml-2">
									<label className="block mb-2 text-sm font-bold text-gray-700" for="c_password">
										Confirm Password
									</label>
									<input
										className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
										id="c_password"
										type="password"
										placeholder="********"
										onChange={(event)=>{setCpass(event.target.value)}}
										name="cpass"
										onBlur={validateInput}
										required
									/>
									{error.cpass && <span className='text-sm font-bold text-red-700'>{error.cpass}</span>}
								</div>
							</div>
							<div className="mb-12 lg:flex lg:justify-between">
								<div className="w-full mb-4 lg:mr-2 lg:mb-0">
								<label for="display" className="mb-2 text-sm font-bold text-gray-700 mr-4">Are you willing to be contacted by other alumni?</label>
								<input 
									id="display"
									type="checkbox" 
									value=""
									onChange={(event)=>{setDisplay(event.target.checked)}} 
									class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
								</div>
							</div>
							
							<div className="mb-6 text-center">
								<button
									className="w-auto px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-blue-500 focus:shadow-outline"
									type="submit"
								>
									Register Account
								</button>
								{error.gen && <span className='block mt-2 text-sm font-bold text-red-700'>{error.gen}</span>}
							</div>
							<div  className={"pt-4 mb-4 m-auto "+ mainprogress} role="status">
								<svg aria-hidden="true" class="w-8 h-8 m-auto text-center text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
									<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
								</svg>
							</div>
							<hr className="mb-6 border-t" />
							<div className="text-center">
								<a
									className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
									href="./login"
								>
									Already have an account? Login!
								</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
        </>
    );
}