import AlumniCard from "./AlumniCard"
import { useEffect, useState } from "react"
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../Firebase";
export default function AlumniList() {
  // Checking if the content is loading
  const [loading, setloading] = useState(false);
  // Store Firbase Data
  const [alumni , setAlumni] = useState([]);
  //
  const [search, setSearch] = useState("");
  // Show data to the user
  const [show, setshow] = useState([]);

  const getAlumni = async() => {
    const list = await getDocs(collection(db , "Alumni"));
    setAlumni(list.docs.map( (doc) => ({id:doc.id , ...doc.data()}) ));
    setloading(true);
  }
  useEffect(() => {
    getAlumni()
  }, [])

  useEffect(() => {
    setshow(alumni);
    setloading(false);
  },[loading]);

  const handleClick = (e) =>{
      e.preventDefault();

      setshow(alumni.filter( alum => {
        for (var i in alum){
          if (typeof alum[i] === 'string'){
            if (alum[i].toLowerCase().includes(search.toLowerCase())){
              return true;
            }
          }
        }
        return false;
      }));
  }
    return(
        <>

        <div className="bg-white">  
          <div className="container px-6 py-10 mx-auto">

        <div className="w-full flex justify-evenly">
          <div className="relative w-full flex m-5">

          <form onSubmit={(e) =>handleClick(e)} className="input w-full">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" className="w-5 h-5 text-gray-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          </div>
          
          <input type="search" id="default-search" className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 w-full rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-700 " placeholder="Search by Name, Batch, Specialty, Location..." required onChange={(e)=>setSearch(e.target.value)}/>
          <button type="button" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 ">Search</button>
          </form>
      </div>
          </div>
        <div className="w-full grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-4">
          {
            loading ?
            ( <div className="fixed top-0 right-0 h-screen w-screen z-50 flex justify-center items-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div> ) :
            (show.map((a)=> {
              if(a.Approved){
                return <AlumniCard FirstName={a.Name} MiddleName={a.MiddleName} LastName={a.LastName} Special={a.Special} Department={a.Department} Batch={a.Batch} Location={a.Location} ImageURL={a.imageURL} id={a.uid}/>;
              }
            }))
          }
        </div>
        </div>
        </div>

        </>
    );
};