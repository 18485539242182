import { Nav } from "../Components/Nav";
import { Footer } from "../Components/Footer";
import AdminModule from "../Components/AdminModule";

export default function Calendar() {
    return(
      <>       
        <Nav/>
        <AdminModule />
        <Footer />
      </>
    );
}