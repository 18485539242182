import logo from './../Assets/logo.png';
import { View } from 'react-native';

export const Footer = () => {
    return (
      <div className="relative bg-white-100">
        <View style={{height: 2, width: '100%', backgroundColor: '#909090',}}></View>
        <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="grid gap-16 row-gap-10 lg:grid-cols-6">
            <div className="md:max-w-md lg:col-span-2">
              <a
                href="/dashboard"
                aria-label="Go home"
                title="GGSMC Alumni"
                className="inline-flex items-center"
              >
                
                <span className="inline-flex items-center text-xl font-bold tracking-wide text-gray-900 uppercase">
                <img src={logo} alt="Logo" className="items-left" style={{width: 100, height: 65}}/>
                GGSMC Alumni
                </span>
              </a>
            </div>
          </div>
          <div className="flex flex-col justify-between pb-10 sm:flex-row">
            <p className="text-sm text-gray-900">
              United by our legacy, shaping a brighter future together.
              <br/><br/>
              © Copyright 2023 Ggsmcalumni.com - All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    );
  };