import mainEnterance from './../Assets/main-enterance.png';

export const Header = () => {
    const goToTop = () => {
          window.scrollTo({
              top: 1000,
              behavior: 'smooth',
          });
      };
    return (
      <div className="relative flex flex-col lg:pt-0 lg:flex-col lg:pb-0">
        <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full md:mb-4 sm:mb-8 md:mt-0 sm:mt-8"
            src={mainEnterance}
            alt=""
          />
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
            {/* <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-cyan-600">
              Connect
            </p> */}
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Connect with {' '}
              <span className="inline-block text-blue-900">
              GGSMC Alumni 
              </span>{' '}
              <br className="hidden md:block" />
              around the world.
            </h2>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
            Welcome to our Alumni Network, a vibrant community connecting graduates and current students to help you gain from each others experience, expertise and influence. Here, you'll find opportunities to network, mentor, and engage with fellow alumni who share your passion and drive. Stay updated with the latest events and achievements of our alma mater, and celebrate your own milestones with a community that understands and values your journey. Join us in building a legacy of connection, support, and pride. Together, we continue to shape the future, inspired by our shared past.
            </p>
            <div className="flex items-center">
              <a
                href="/alumni"
                className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-900 hover:bg-deep-blue-accent-700 focus:shadow-outline focus:outline-none"
              >
                Alumni
              </a>
              <button
                onClick={goToTop}
                aria-label=""
                className="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-blue-accent-700"
              >
                More info
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };