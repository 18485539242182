import { useState, useContext, useEffect } from 'react';
import { Nav } from "../Components/Nav"
import { Footer } from "../Components/Footer"
import {auth} from '../Firebase'
import { collection, getDocs } from "firebase/firestore";
import { signInWithEmailAndPassword, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { db } from "../Firebase";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';
import main from './../Assets/convocation.png';

export default function LoginPage(){
    const {currentUser} = useContext(AuthContext);
    // Email 
    const [email, setemail] = useState("");
    // Password
    const [pass, setPass] = useState("");
    // Navigate
    const navigate = useNavigate()
    // Dispatch
    const {dispatch} = useContext(AuthContext);
    const [data , setData] = useState([]);
    const [progress, setProgress] = useState("hidden");

    var signup = localStorage.getItem("signup");

    const [error, setError] = useState({
		gen: '',
        success: '',
	  });

    const isApproved = (id) => {
        for (var k in data){
            if(data[k].id === id){
                localStorage.setItem("profileImage", data[k].imageURL);
                return data[k].Approved;
            }
        }
        return false;
    }

    const isAdmin = (id) => {
        for (var k in data){
            if(data[k].id === id){
                return data[k].Admin;
            }
        }
        return false;
    }

    const getData = async() => {
        const list = await getDocs(collection(db , "Alumni"));
        setData(list.docs.map( (doc) => ({id:doc.id , ...doc.data()}) ));
    }

    const loginApproved = (user) => {
        console.log(data);
        if (data.length !== 0){
            if (user.emailVerified){
                localStorage.setItem("signup", false);
                signup = false;
                if (isApproved(user.uid)){
                    localStorage.setItem("approval", true);
                    if (isAdmin(user.uid)){
                        localStorage.setItem("admin", true);
                    } else {
                        localStorage.setItem("admin", false);
                    }
                    dispatch({type:"LOGIN", payload:user});
                    setProgress("hidden");
                    navigate("/dashboard");
                } else {
                    setProgress("hidden");
                    setError(prev => {
                        return { ...prev, gen: "Your account hasn't been approved yet. Please reach out to admin@ggsmcalumni.com for further assistance!" };
                        });
                }
            } else {
                setProgress("hidden");
                setError(prev => {
                    return { ...prev, gen: "Please verify your email address!" };
                    });
            }
        } else {
            setProgress("hidden");
            setError(prev => {
                return { ...prev, gen: "Please try again!" };
                });
        }
    }

    // Handling Login
    const handleLogin = (e) =>{
        e.preventDefault();
        setProgress("visible");
        signInWithEmailAndPassword(auth, email , pass).then((userCredential) => {
          // User is now Signed in
            const user = userCredential.user;
            loginApproved(user);

        }).catch(e => setError(prev => {
            setProgress("hidden");
            return { ...prev, gen: e.message };
            }));
    }

    const passwordReset = () => {
        const auth = getAuth();

        sendPasswordResetEmail(auth, email).then((r) => {
            setError(prev => {
                return { ...prev, success: "Password reset email sent!", gen: "" };
                })
        }).catch(e => setError(prev => {
            return { ...prev, gen: e.message, success: ""};
            }));
    }

    useEffect(() => {
        console.log(data);
        if (data.length === 0){
            getData();
        }
        if (currentUser){
            if (currentUser.emailVerified){
                if (localStorage.getItem("approval") === "true"){
                    navigate("/dashboard");
                }
            }
        }
    },[handleLogin]);

    return(
      <>       
<div className="bg-white dark:bg-gray-900">
        <div className="block justify-center h-screen">
        <Nav/>
        <div className="flex h-[90%]">
            <div style={{'--image-url': `url(${main})`}} className="hidden bg-cover lg:block lg:w-2/3 bg-[image:var(--image-url)] bg-white">
                <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                    <div>   
                        <h2 className="text-4xl font-bold text-white shadow-black">Connect with <b>GGSMC Alumni</b></h2>
                        <h2 className="text-4xl font-bold text-white shadow-black">around the world.</h2>
                    </div>
                </div>
            </div>
            
            <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                <div className="flex-1">
                    <div className="text-center">
                        <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">Login</h2>
                        <p className="mt-3 text-gray-500 dark:text-gray-300">to access your account</p>
                    </div>

                    <div className="mt-8">
                        <form onSubmit={handleLogin}>
                            <div>
                                <label for="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email Address</label>
                                <input type="email" name="email" id="email" placeholder="example@ggsmcalumni.com" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 required" 
                                onChange={(e) => setemail(e.target.value)}/>
                            </div>

                            <div className="mt-6">
                                <div className="flex justify-between mb-2">
                                    <label for="password" className="text-sm text-gray-600 dark:text-gray-200">Password</label>
                                    <a onClick={passwordReset} className="text-sm text-blue-500 focus:text-blue-500 hover:text-blue-500 hover:underline">Forgot password?</a>
                                </div>

                                <input type="password" name="password" id="password" placeholder="password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 required" 
                                onChange={(e) => setPass(e.target.value)}/>
                            </div>

                            <div className="mt-6">
                                <button
                                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                                    type="submit"
                                    >
                                    Sign in
                                </button>
                                {signup && signup === "true" && <span className='block text-center mt-4 text-sm font-bold text-red-700'>Please verify your email address!</span>}
                                {error.gen && <span className='block text-center mt-4 text-sm font-bold text-red-700'>{error.gen}</span>}
                                {error.success && <span className='block text-center mt-4 text-sm font-bold text-gray-700'>{error.success}</span>}
                            </div>

                            <div  className={"pt-4 m-auto "+ progress} role="status">
								<svg aria-hidden="true" class="w-8 h-8 m-auto text-center text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
									<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
								</svg>
							</div>

                        </form>

                        <p className="mt-6 text-sm text-center text-gray-400">Don&#x27;t have an account yet? <a href="/signup" className="text-blue-500 focus:outline-none focus:underline hover:underline">Sign up</a>.</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
      </>
    )
}