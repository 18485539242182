import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import AlumniPage from './Pages/AlumniPage';
import Resources from './Pages/Resources';
import UpdateP from './Pages/Update';
import SignUp from './Pages/SignUp';
import Calendar from './Pages/Calendar';
import Admin from './Pages/Admin';
import AddBlogPage from './Pages/AddBlogPage';
import { useContext } from 'react';
import { AuthContext } from './Context/AuthContext';
import Portfolio from './Pages/Portfolio';
function App() {

  const {currentUser} = useContext(AuthContext);
  const approval = localStorage.getItem("approval");
  const admin = localStorage.getItem("admin");
  const RequireAuth = ({children}) => {
    return currentUser && approval === "true" ? (children) : <Navigate to = "/login"/>
  }
  const RequireAdmin = ({children}) => {
    return currentUser && approval === "true" && admin === "true"? (children) : <Navigate to = "/login"/>
  }
  return (
  <>
    <Routes>
    <Route exact path = "/" element = {<Dashboard/>} />
    <Route exact path = "/signup" element = {<SignUp/>} />
    <Route exact path = "/login" element = {<LoginPage/>} />

    <Route exact path = "/portfolio" element = {<RequireAuth><Portfolio/></RequireAuth>} />
    <Route exact path = "/admin" element = {<RequireAdmin><Admin/></RequireAdmin>} />
    <Route exact path = "/dashboard" element = {<Dashboard/>} />
    <Route exact path = "/alumni" element = {<RequireAuth><AlumniPage/></RequireAuth>} />
    <Route exact path = "/blogs" element = {<RequireAuth><Resources/></RequireAuth>} />
    <Route exact path ='/update' element = {<RequireAuth><UpdateP/></RequireAuth>}/>
    <Route exact path ='/calendar' element = {<RequireAuth><Calendar/></RequireAuth>}/>
    <Route exact path ='/addblog' element = {<RequireAuth><AddBlogPage/></RequireAuth>}/>
    </Routes>
  </>
  );
}

export default App;
