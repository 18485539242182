import {Link} from 'react-router-dom';
function AlumniCard({FirstName, MiddleName, LastName, Special, Department , Batch , Location , ImageURL , id}) {
    ImageURL = !ImageURL ? "https://firebasestorage.googleapis.com/v0/b/alumni-portal-bc1dc.appspot.com/o/image%2Ficon-user.png?alt=media&token=227c1769-ed75-4865-b0c8-7e43a235a810" : ImageURL;
    return (
        <>
        <Link to="/portfolio" state={{ id : id }}>
         <div className="flex flex-col items-center p-8 transition-colors duration-200 transform cursor-pointer group hover:bg-blue-700 rounded-xl hover:-translate-y-7 ease-out hover:ease-in shadow-xl hover:shadow-2xl">
                        <img className="object-cover w-32 h-32 rounded-full ring-4 ring-blue-700 group-hover:ring-white" src={ImageURL} alt="" />
                        
                        {MiddleName && <h1 className="mt-4 text-2xl font-semibold  capitalize text-black group-hover:text-white">{FirstName+" "+MiddleName+" "+LastName}</h1>}
                        {!MiddleName && <h1 className="mt-4 text-2xl font-semibold  capitalize text-black group-hover:text-white">{FirstName+" "+LastName}</h1>}
                        <p className="font-semibold capitalize text-gray-700 group-hover:text-gray-300">{Department}</p>
                        <p className="font-semibold capitalize text-gray-700 group-hover:text-gray-300">{Special}</p>

                        <span className="mt-8 mx-0 pb-2 px-2 bg-blue-700 group-hover:bg-white rounded-2xl ">
                        <p className="mt-2  capitalize font-semibold text-white group-hover:text-blue-700 px-2">{Batch}</p>
                        </span>
                        <p className="mt-2 capitalize text-gray-700 group-hover:text-gray-300">{Location}</p>
                        
                        {/* <div className="flex mt-3 -mx-2">
                            { Linkedin && <a href={Linkedin} className="mx-2  text-blue-800  hover:text-gray-300 group-hover:text-white" aria-label="Reddit" >
                                <svg className="w-8 h-8 fill-current" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                   <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z">
                                    </path>
                                </svg>
                            </a> }
                        </div> */}
                    </div>
                    </Link>
        </>
    );
}

export default AlumniCard;