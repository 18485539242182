import React from 'react';

const Home = ({imageUrl, FirstName, MiddleName, LastName, Location, Batch, Department, Special, Web, Email, Number, About, Display }) => {
  imageUrl = !imageUrl ? "https://firebasestorage.googleapis.com/v0/b/alumni-portal-bc1dc.appspot.com/o/image%2Ficon-user.png?alt=media&token=227c1769-ed75-4865-b0c8-7e43a235a810" : imageUrl;
  return (
    <div className="pt-16 pb-16">
      <div className="w-full max-w-md px-4 mx-auto sm:px-7 lg:max-w-4xl lg:px-6">
        <div className="lg:w-1000 lg:grid lg:grid-cols-2 lg:divide-x lg:divide-gray-200">
          <div className="lg:pr-14">
          <div className='mt-12' >
          <img className="object-cover w-60 h-60 rounded-lg" src={imageUrl} alt="" />
          <div className='mt-8' >
            {MiddleName && <p className='text-4xl font-bold inline border-b-4 text-gray-800 border-blue-700'>{FirstName+" "+MiddleName+" "+LastName}</p>}
            {!MiddleName && <p className='text-4xl font-bold inline border-b-4 text-gray-800 border-blue-700'>{FirstName+" "+LastName}</p>}
          </div>
          
          </div>
          </div>
          <section className="pt-8 lg:mt-0 lg:pl-14">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <tbody>
                      {Location && <tr class="border-b border-gray-200 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Location
                          </th>
                          <td class="px-6 py-4">
                             {Location}
                          </td>
                      </tr>}
                      {Batch && <tr class="border-b border-gray-200 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Batch
                          </th>
                          <td class="px-6 py-4">
                              {Batch}
                          </td>
                      </tr>}
                      {Department && <tr class="border-b border-gray-200 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Job Profile
                          </th>
                          <td class="px-6 py-4">
                              {Department}
                          </td>
                      </tr>}
                      {Special && <tr class="border-b border-gray-200 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Specialty
                          </th>
                          <td class="px-6 py-4">
                              {Special}
                          </td>
                      </tr>}
                      {Web && <tr class="border-b border-gray-200 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                          Web address
                          </th>
                          <td class="px-6 py-4">
                              <a class="text-sm text-blue-500 focus:text-blue-500 hover:text-blue-500 hover:underline" href={Web}>{Web}</a>
                          </td>
                      </tr>}
                      {Display && Email && <tr class="border-b border-gray-200 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Email
                          </th>
                          <td class="px-6 py-4">
                              {Email}
                          </td>
                      </tr>}
                      {Display && Number && <tr class="border-b border-gray-200 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                            Phone Number
                          </th>
                          <td class="px-6 py-4">
                              {Number}
                          </td>
                      </tr>}
                  </tbody>
              </table>
          </div>

          </section>
        </div>
      </div>

      {About && <div className="max-w-md px-4 mx-auto sm:px-7 lg:max-w-4xl lg:px-6">
        <div className="lg:grid lg:grid-cols-1 lg:divide-x lg:divide-gray-200">
          <div className="lg:pr-14">
            <div className='mt-12' >
                <div className='mt-8' >
                  <p className='text-3xl font-bold inline border-b-4 text-gray-800 border-blue-700'>About</p>
                  <p className="max-w-s mb-2 mt-8 text-justify text-gray-700">
                    {About}
                  </p>
                </div>
            </div>
        </div> 
        </div>
        </div>}


    </div>

    
  );
};

export default Home;