import { Nav } from "../Components/Nav"
import { Footer } from "../Components/Footer"
import { BlogHeader } from "../Components/Resources/BlogHeader"
import { BlogCard } from "../Components/Resources/BlogCard"
import { useEffect, useState } from "react"
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Firebase";

export default function Resources(){
  const [blog, setBlog] = useState([]);
  const [showBlog, setShowBlog] = useState([]);
  const getBlog = async() => {
    const list = await getDocs(collection(db , "Blog"));
    setBlog(list.docs.map( (doc) => ({id:doc.id , ...doc.data()}) ));
    setShowBlog(blog);
  }
  useEffect(() => {
    getBlog();
    console.log(showBlog);
  },[])
 
  
  return(
      <>
        <Nav/>
        <BlogHeader/>
        <div className="w-screen lg:flex lg:flex-wrap">
        <div className="flex justify-center items-center lg:ml-auto lg:px-60 sm:w-full grid grid-cols-1 gap-8 xl:mt-16 lg:grid-cols-1 pb-4">
          {
            (blog.map((b)=><BlogCard Title={b.Title} Desc={b.Desc} Tags={b.Tags} Name={b.name} id={b.uid} />) )
          }        
        </div>
        </div>
        <Footer />
      </>
    )
}