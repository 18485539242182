import React, { useState, useEffect } from 'react'
import {useLocation ,Navigate} from 'react-router-dom'
import {collection ,query, getDocs,where} from "firebase/firestore";
import { db } from '../Firebase';
import { Nav } from '../Components/Nav'
import { Footer } from '../Components/Footer'
import Home from '../Components/Portfolio/Home'
import Blogs from '../Components/Portfolio/Blogs'
function Portfolio() {
    const location = useLocation();
    const { id } = location.state || "";
    console.log(id);
    const RequireID = ({children}) => {
        return id ? (children) : <Navigate to = "/alumni"/>
      }
    useEffect(() => {
      window.scrollTo(0 , document.body.scrollHeight);
      window.scrollTo({top:0 , behavior:"smooth"}); 
    }, [])
    
    const [res, setRes] = useState([]);
    
    const [Name,setName]=useState("");
    const [Batch,setBatch]=useState("");
    const [Department,setDepartment]=useState("");
    const [LastName,setLastName]=useState("");
    const [LocationA,setLocation]=useState("");
    const [MiddleName,setMiddleName]=useState("");
    const [Phone,setPhone]=useState("");
    const [Special,setSpecial]=useState("");
    const [Web,setWeb]=useState("");
    const [email,setEmail]=useState("");
    const [About,setAbout]=useState("");
    const [Display,setDisplay]=useState(false);
    const [imageURL,setImageURL]=useState("");

    const [blogs, setBlogs] = useState([]);

    useEffect(()=>{
    const getUsers=async()=>{
        const data=await getDocs(query(collection(db,"Alumni"), where("uid","==",id)));
        setRes(data.docs.map((doc)=>({...doc.data(),id:doc.id})));
    }
    getUsers();
    
    },[]);

    const getBlogs = async() => {
        const list = await getDocs(query(collection(db , "Blog"),where("uid","==",id)));
        setBlogs(list.docs.map( (doc) => ({id:doc.id , ...doc.data()}) ));
      }
      useEffect(() => {
          getBlogs();
      },[])

    useEffect(() => {
        if(res.length >0)
        {
            setName(res[0].Name);
            setBatch(res[0].Batch);
            setDepartment(res[0].Department);
            setLastName(res[0].LastName);
            setLocation(res[0].Location);
            setMiddleName(res[0].MiddleName);
            setPhone(res[0].Phone);
            setSpecial(res[0].Special);
            setWeb(res[0].Web);
            setEmail(res[0].email);
            setImageURL(res[0].imageURL);
            setAbout(res[0].About);
            setDisplay(res[0].Display);
        }
    
    }, [res])
  
    
    return (
    <>
        <Nav />
        <RequireID>
        <Home imageUrl={imageURL} FirstName={Name} MiddleName={MiddleName} LastName={LastName} Location={LocationA} Batch={Batch} Department={Department} Special={Special} Web={Web} Email={email} Number={Phone} About={About} Display={Display}/>
        <Blogs data={blogs}/>
        </RequireID>
        <Footer />
    </>
  )
}

export default Portfolio